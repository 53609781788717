import ApiDrivenModel from "../ApiDrivenModel";

export default class Customer extends ApiDrivenModel {
	static ENDPOINT = "/Customer";
	static PATHS = {
		id: true,
		handle: true,
		first_name: true,
		last_name: true,
		phone_mobile: true,
		email: true,
		profile_image: true,
		primary_contact: ["*"],
	};

	id;
	handle;
	first_name;
	last_name;
	phone_mobile;
	email;
	profile_image;
	/** @type Customer_Contact */
	primary_contact;
}
