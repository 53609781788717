import React from "react";
import { createRoot } from "react-dom/client";
import { DOM_ROOT_ID } from "./constants";

import "./customtheme.scss";
import App from "./App";

const container = document.getElementById(DOM_ROOT_ID || "root");

const url = new URL(window.location.href);
["nocache", "manscan"].forEach(param => {
	if (url.searchParams.has(param)) {
		url.searchParams.get(param).toLowerCase() === "false"
			? localStorage.removeItem(param)
			: localStorage.setItem(param, "true");
		url.searchParams.delete(param);
	}
});
if (url.toString() !== window.location.href) {
	window.history.pushState(null, null, url);
}

const root = createRoot(container);
root.render(
  	<React.StrictMode>
    	<App tab="home" />
   	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
