export class Constant
{
	static #Values = {};

	static Set(key, value) {
		this.#Values[key] = value;
	}
	static Get(key) {
		return this.#Values[key];
	}
}