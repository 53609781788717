import ApiDrivenModel from "./ApiDrivenModel";

export default class Profile extends ApiDrivenModel {
	static async GetCurrent(user) {
		if (Profile.Current) {
			return Profile.Current;
		}

		const result = await Profile.FetchBy({
			filter: [{ property: 'username', value: user.username, }],
		});
		Profile.Current = result;

		return Profile.Current;
	}


	static ENDPOINT = "/Profile";
	static SEARCH_PATHS = {
		id: true,
		email: true,
		family_name: true,
		given_name: true,
		organization: [
			'id',
			'name',
			'logo',
			'branding'
		],
		organization_name: true
	};
	static LOAD_PATHS = {
		id: true,
		email: true,
		family_name: true,
		given_name: true,
		organization: [
			'id',
			'name',
			'logo',
			'branding'
		],
		organization_name: true
	};
}
