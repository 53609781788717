import ApiDrivenModel from "../ApiDrivenModel";
import CustomerListItem from "./CustomerListItem";
import Item from "../Item";

export default class CustomerList extends ApiDrivenModel {
	static ENDPOINT = "Customer_List";
	static PATHS = {
		id: true,
		label: true,
		type: {
			key: true,
		},
		items: {
			id: true,
			item: Item.PATHS,
		},
	};

	id;
	label;
	/** @type CustomerListType */
	type;
	/** @type CustomerListItem[] */
	items;

	constructor(properties) {
		super(properties);

		// Setup children
		if (this.items) {
			this.items = this.items.map(item => new CustomerListItem(item));
		}
	}

	async save() {
		// API save removes array properties so items[] will be gone afterward; reserve them, then save, then set back
		const items = this.items;
		await super.save();
		if (items?.length) {
			const savedItems = await Promise.allSettled(items.map(listItem => {
				listItem.customer_list = this.id;
				return listItem.save();
			}));
			this.items = items.filter((item, idx) => savedItems.at(idx).status === "fulfilled");
		}
	}

	/**
	 *
	 * @param {CustomerListItem|Item|Number} item
	 */
	addItem(item) {
		this.items ??= [];
		if (item instanceof CustomerListItem) {
			this.items.push(item)
		}
		else if (item instanceof Item) {
			this.items.push(new CustomerListItem({ item }));
		}
		else if (Number.isSafeInteger(item) && item > 0) {
			this.items.push(new CustomerListItem({ item: { id: item } }));
		}
		return this;
	}

	/**
	 *
	 * @param {Array<CustomerListItem|Item|Number>} items
	 */
	addItems(items) {
		items?.forEach(item => this.addItem(item));
	}

	static FindById(id) {
		return this.FetchBy({
			filter: { id },
		});
	}
}
