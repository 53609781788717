import ApiDrivenModel from "../ApiDrivenModel";

export default class CustomerListType extends ApiDrivenModel {
	static ENDPOINT = "Customer_List_Type";
	static PATHS = {
		id: true,
		key: true,
		label: true,
	};

	id;
	key;
	label;
}
