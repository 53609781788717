import "./LoadMask.scss";

function LoadMask({ show }) {
	return (
		<div className={'load-mask' + (show ? ' show' : '')}>
			<div className="loading-text">
				Loading
				<span className="load__dot">.</span>
				<span className="load__dot">.</span>
				<span className="load__dot">.</span>
			</div>
			
		</div>
	);
}

export default LoadMask;
