import ApiDrivenModel from "./ApiDrivenModel"
export default class OrganizationMember extends ApiDrivenModel{

}
OrganizationMember.ENDPOINT = "/Organization_Member";
OrganizationMember.PATHS = {
	id: true,
	profile: {
		id: true,
		username: true,
		email: true,
		given_name: true,
		family_name: true,
		pronouns: true,
		picture: true,
		mobile_phone: true,
		last_activity: true
	},
    organization:{
        id: true,
        name: true,
        formatted_address: true
    },
    role: {
        id: true,
        is_administrator: true,
        label: true
    },
    status: true,
    verified: true,
	receive_inquiry: true
};