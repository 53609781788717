// import "./App.scss";
import React from "react";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import Appin from "./Appin";
import {AuthProvider} from "./auth/useAuth";
import CatalogContext from "./contexts/CatalogContext";
import "./global.scss";

import ReactGA from "react-ga4";
import {CustomerProvider} from "./auth/useCustomer";
import { CatalogSelectionContextProvider } from "./hooks/useCatalogSelectionContext";

const queryClient = new QueryClient();

function App() {

	const [catalogConfig, setCatalog] = React.useState(null);

	const TRACKING_ID = "G-9B5G9811WM";
	ReactGA.initialize(TRACKING_ID);

	return (
		<>
			<QueryClientProvider client={queryClient}>
				<AuthProvider>
					<CustomerProvider>
						<CatalogContext.Provider value={[catalogConfig, setCatalog]}>
							<CatalogSelectionContextProvider>
								<Appin />
							</CatalogSelectionContextProvider>
						</CatalogContext.Provider>
					</CustomerProvider>
				</AuthProvider>
				<ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
			</QueryClientProvider>
		</>
	);
}

export default App;
