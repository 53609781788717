import React from "react";
import ReactGA from "react-ga4";

import { SINGLE_PAGE, REMOTE_BASE_URL, PAGE_TITLE_SUFFIX } from "../constants";

export const isEmpty = (value) => [undefined, null, ""].includes(value);
export const hasValue = (value) => !isEmpty(value);
export const setTitle = (title) => (document.title = title + PAGE_TITLE_SUFFIX);
export const trimSlashes = (str) => str.replace(/^\/|\/$/g, "");

export function setPageIcon(icon) {
	// Add fav/share icons
	let link = document.querySelector("link[rel~='icon']");
	if (!link) {
		link = document.createElement("link");
		link.rel = "icon";
		document.getElementsByTagName("head")[0].appendChild(link);
	}
	link.href = processImagePath(icon);
}
export function setPageImage(image) {
	// Add fav/share icons
	let tag = document.querySelector("meta[property='og:image']");
	if (!tag) {
		tag = document.createElement("meta");
		tag.setAttribute("property", "og:image");
		document.getElementsByTagName("head")[0].appendChild(tag);
	}
	tag.setAttribute("content", processImagePath(image));
}
export function setPageDescription(description) {
	// Add fav/share icons
	let tag = document.querySelector("meta[property='og:description']");
	if (!tag) {
		tag = document.createElement("meta");
		tag.setAttribute("property", "og:description");
		document.getElementsByTagName("head")[0].appendChild(tag);
	}
	tag.setAttribute("content", processImagePath(description));
}
export function setFavIcon(source) {
	let link = document.querySelector("link[rel~='icon']");
	if (!link) {
		link = document.createElement("link");
		link.rel = "icon";
		document.getElementsByTagName("head")[0].appendChild(link);
	}
	link.href = source;
}

export function processImagePath(path, size = "") {
	if (!path) return path;
	if (path[0] === "/") {
		path = REMOTE_BASE_URL + path;
	}
	let lastIndex = path.lastIndexOf(".") + 1;
	const fileExt = path.substr(lastIndex);

	if (["svg", "webp"].includes(fileExt.toLowerCase())) {
		return path;
	}

	const sizeVariationMap = {
		mini: "m",
		thumb: "th",
		full: "f",
		zoom: "z",
	};
	let vers = sizeVariationMap[size] || size;
	vers = vers ? vers + "." : "";

	return path.substr(0, lastIndex) + vers + fileExt;
}
export const getBossWrapper = () => {
	if (!window.bossWrapper) {
		window.bossWrapper = document.querySelector("._boss");
	}
	return window.bossWrapper;
};

export async function download(url) {
	const link = document.createElement("a");
	link.href = url + "?download=" + Math.round(Math.random() * 100);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}

export function importFont(url) {
	window.importedFonts = window.importedFonts || [];
	if (window.importedFonts.includes(url)) {
		return;
	}

	const css = `@import url('${url}');`;

	if (!window.styleImports) {
		const head = document.head || document.getElementsByTagName("head")[0];
		window.styleImports = document.createElement("style");
		head.appendChild(window.styleImports);
	}
	window.styleImports.setAttribute("type", "text/css");

	if (window.styleImports.styleSheet) {
		window.styleImports.styleSheet.cssText = css;
	} else {
		window.styleImports.appendChild(document.createTextNode(css));
	}

	window.importedFonts.push(url);
}

export function getTopLevelDomain(hostname) {
	if (hostname.indexOf(".") === -1) {
		return hostname;
	}
	const regexp = /[-\w]+\.(?:[-\w]+\.xn--[-\w]+|[-\w]{3,}|[-\w]+\.[-\w]{2})$/i;
	const matches = hostname.match(regexp);
	return matches && matches[0];
}

// Convert one level json into tree strcture as parent-child depending on id in parent key.
// Children will be added under children key.
export function jsonTree(jsonData, reference = "") {
	if (!jsonData) {
		return [];
	}

	// add default parent:'0' if parent key not exist.
	Object.keys(jsonData).forEach((key) => {
		if (!jsonData[key].parent) {
			jsonData[key].parent = "0";
		}
	});
	// If parent node not found, put this node into root
	Object.keys(jsonData).forEach((key) => {
		if (!jsonData.find((needle) => needle.id === jsonData[key].parent)) {
			jsonData[key].parent = "0";
		}
	});
	const map = {};

	for (const obj of jsonData) {
		obj.reference = reference;
		obj.checked = obj.is_selected || false;
		obj.children = [];

		// If parent already declared with children
		let current_children = map[obj.id]?.children;

		map[obj.id] = obj;
		map[obj.id].children = current_children || [];

		const parent = obj.parent || "-";
		if (!map[parent]) {
			map[parent] = {
				level: -1,
				children: [],
			};
		}
		obj.level = map[obj.parent].level + 1;
		map[parent].children.push(obj);
	}
	return map[0] ? map[0]?.children : [];
}

export function jsonFilters(filters_and_options) {
	filters_and_options.filters.forEach(createFilters);
	function createFilters(filter) {
		// finding options of current filter by reference key.
		let ref = filter.common.reference;
		let filteroptions = filters_and_options.options[ref];
		switch (filter.common.display_type) {
			case "tree":
				// add default parent:'0' if parent key not exist.
				Object.keys(filteroptions).forEach((key) => {
					if (!filteroptions[key].parent) {
						filteroptions[key].parent = "0";
					}
				});
				filter.options = jsonTree(filteroptions); // assign options to its filter

				break;
			case "multiselect":
				filter.options = filteroptions;
				break;
			case "toggle":
				filter.options = filteroptions;
				break;
			default:
				filter.options = [];
				break;
		}
	}
	return filters_and_options.filters;
}

export function isUrl(str) {
	const regexp =
		/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
	return regexp.test(str);
}

export function format(value, options) {
	if (value === undefined) {
		return '';
	}

	if (options.type === "string" && typeof value === "string" && value.includes("\n")) {
		value = value.trim().replace(/\n/g, "<br />");
	}

	if (options.type === "currency") {
		options.precision ??= 2;
	}

	if (options.precision || options.type === "number") {
		/*
			TODO
				- setup a "currency" type with "locale" and use in NumberFormat
				or
				- send min/max precision ... can't force both because max precision is needed for things like ring size, quantity, carat e.g. 7.5 not 7.50
		 */
		const numberValue = +value;
		// even if price, don't add min digit unless price has cents
		const minDigits = (options.type === "currency" || options.reference === "Retail Price") && !Number.isInteger(numberValue) ? options.precision : undefined;
		const formatOptions = { minimumFractionDigits: minDigits, maximumFractionDigits: options.precision };
		if (options.stripTrailingZeroes) {
			formatOptions.trailingZeroDisplay = "stripIfInteger";
		}
		if (options.type === "currency" && options.currency) {
			formatOptions.style = "currency";
			formatOptions.currency = options.currency;
		}
		return new Intl.NumberFormat(undefined, formatOptions).format(numberValue);
	} else if (value && options.type === "link") {
		const link = value;
		const url = new URL(link);
		return React.createElement("a", { href: link, target: "_BLANK", }, url.host);
	} else if (options.type === "json") {
		const jsonObject = JSON.parse(value);
		value = React.createElement(
			"table",
			{ key: value }
		);
		return React.createElement(
			"table",
			{ className: 'json-table', key: Math.random() },
			Object.keys(jsonObject).map(key => [
				React.createElement('tbody', { key }, [
					React.createElement('tr', { key }, [
						React.createElement('th', {key}, key),
						React.createElement('td', {key: key+'-value'}, (typeof jsonObject[key] === "object" ? (
							<table key={key + "-sub-table"} className="json-table">
							<tbody>
							{ Object.entries(jsonObject[key]).map(([label, value]) => (
								<tr key={label}>
									<th>{label}</th>
									<td>{value}</td>
								</tr>
							))}
							</tbody>
							</table>
						) : jsonObject[key])),
					]),
				])
			])
		);
	}
	return value;
}
export function getLink(link) {
	if (!SINGLE_PAGE) {
		return link;
	}
	const searchParams = new URLSearchParams(window.location.search);
	searchParams.set('_loc', link);
	return window.location.pathname + '?' + searchParams.toString();
}

export function highlight_keyword_matches(keywords, nodes, high_class) {
	function search_dom_for_keyword(node) {
		const text = get_dom_search_text(node);

		// Check each term
		for (let term of keywords) {
			if (text.indexOf(term.toLowerCase()) === -1) {
				return false;
			}
		}
		return true;
	}
	function get_dom_search_text(node) {
		let node_text =
			node.dataset.search_text ||
			node.dataset.keywords ||
			node.innerText ||
			node.innerHTML;

		if (node_text.toLowerCase) {
			node_text = node_text.toLowerCase();
		}

		return node_text || "";
	}

	let matches = [];

	if (typeof keywords === "string") {
		keywords = keywords.split(" ");
	}

	// Default high_class
	high_class = high_class || "high";

	// Search
	for (let node of nodes) {
		// Flag to check all keywords
		let pass = search_dom_for_keyword(node);

		// If matches all
		node.classList.toggle(high_class, pass);
		pass && matches.push(node);
	}

	return matches;
}

export function trackPageView() {
	// ReactGA.pageview(window.location.pathname + window.location.search);
	ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
}
export function trackEvent(category, action, label) {
	const eventParams = { category, action, label };
	ReactGA.event(eventParams);
}

/**
 * Delay execution of function (fn) for multiple calls.
 * Execution will occur when this has not been called for the delay.
 *
 * @param {string} buffer_key Unique identifier to use as a buffer key.
 * @param {Function} fn Action to execute
 * @param {int} delay_ms Milliseconds without call to execute
 */
export function addCascadingEvent(buffer_key, fn, delay_ms) {
	if (!window._cascadeEvents) {
		window._cascadeEvents = [];
	}

	let _cascadeEvents = window._cascadeEvents;
	if (!_cascadeEvents[buffer_key]) {
		_cascadeEvents[buffer_key] = {}; // create new object to hold cascade event
	} else if (_cascadeEvents[buffer_key].timer) {
		window.clearTimeout(_cascadeEvents[buffer_key].timer); // stop old timer
	}

	_cascadeEvents[buffer_key].fn = fn;
	_cascadeEvents[buffer_key].timer = setTimeout(() => {
		delete _cascadeEvents[buffer_key];
		fn();
	}, delay_ms);
}

let _blockedEvents = {};
/**
 * Execute the function and block future executions supplied with the buffer_key for delay in ms.
 * @param {string} buffer_key Unique identifier to use as a buffer key.
 * @param {Function} fn Action to execute
 * @param {int} delay_ms Milliseconds without call to execute
 */
export function addBlockingEvent(buffer_key, fn, delay_ms) {
	// execute a function if an event and block re-execution
	if (!delay_ms) {
		delay_ms = 500;
	}
	if (!_blockedEvents[buffer_key]) {
		// set block
		_blockedEvents[buffer_key] = true;

		// execute
		fn();

		// remove block after time
		setTimeout(function () {
			delete _blockedEvents[buffer_key];
		}, delay_ms);
	}
}

/**
 * Watch the HTMLElement target for changes in it's dimensions.  The
 * responsive watch will check the width of the node and assign the
 * class which it falls within.  It will also toggle "landscape" or
 * portrait based on it's dimensions.
 *
 * The default standards are:
 *
 * <b>For normal HTML Elements</b>
 * <code>
 * 	<pre>
 * 	const standard_sizes = {
 * 		'0': 'sz-xsm',
 * 		'526': 'sz-sm',
 * 		'768': 'sz-md',
 * 		'1170': 'sz-lg'
 * 	};
 * 	</pre>
 * </code>
 *
 * <b>For the window / document.body</b>
 * <code>
 * 	<pre>
 * 	const window_sizes = {
 * 		'580': 'mobile',
 * 		'768': 'tablet',
 * 		'1170': 'desktop',
 * 		'1600': 'widescreen'
 * 	};
 * 	</pre>
 * </code>
 *
 * @param {HTMLElement} target - The node to watch for size changes
 * @param {object} sizes - (optional) An associative object mapping min size for class.
 *
 * @fires target#onresize
 *
 * @see {@link responsive_watch}
 * @see {@link responsive_check}
 */
export function add_responsive_watch(target, sizes) {
	target.responsive_sizes = sizes;
	_responsive_elements.push(target);

	// trigger initial check
	responsive_check(target);

	!responsive_check_set && responsive_watch();
}
/**
 * Array of elements to watch for dom size changes.  Defaults
 * [window];
 * @see {@link responsive_watch}
 */
const _responsive_elements = [window];
let responsive_check_set = false;

/**
 * Function to watch for responsive changes and call checks.
 *
 * @fires target#onresize
 *
 * @see {@link add_responsive_watch}
 * @see {@link responsive_check}
 */
export function responsive_watch() {
	const do_check = () => {
		// Start a blocking event to only execute every $delay milliseconds
		addBlockingEvent(
			"window-resize-responsive-watch",
			() => {
				for (const element of _responsive_elements) {
					responsive_check(element);
				}
			},
			100
		);
	};

	window.onResize && window.onResize();

	// Add an occasional updater (added issues where responsive watch is added to a DOM node,
	// but it is not yet on DOM)
	setInterval(() => do_check(), 2000);

	// Solves issue with Safari not allowing typing within inputs until window is resized
	setTimeout(function () {
		responsive_check(window, true);
	}, 50);
	responsive_check_set = true;
}
/**
 * Do a responsive check and apply size classes.
 * @param {HTMLElement} target - The node to watch for size changes
 * @param {bool} force_check - (optional) Force class asignment after check

 * @fires target#onresize
 * 
 * @see {@link responsive_watch}
 * @see {@link responsive_check}
 */
export function responsive_check(target, force_check) {
	const standard_sizes = {
		0: "sz-xsm",
		526: "sz-sm",
		768: "sz-md",
		1170: "sz-lg",
	};
	const window_sizes = {
		580: "mobile",
		1170: "tablet",
		1600: "desktop",
		10000: "widescreen",
	};

	const is_window = target === window;
	target = is_window ? document.body : target;

	const sizes =
		target.responsive_sizes || (!is_window ? standard_sizes : window_sizes);
	const mode_prefix = target.responsive_prefix ? target.responsive_prefix : "";

	const target_width = is_window ? window.innerWidth : target.offsetWidth;
	const target_height = is_window ? window.innerHeight : target.offsetHeight;

	// detect change
	if (force_check || (target_width && target_width !== target.previous_width)) {
		// save width for next run
		target.previous_width = target_width;

		// add a class to identify resizing
		target.classList.add("resizing");

		// find largest match within bounds
		const size_codes = Object.values(sizes);
		const size_widths = Object.keys(sizes);
		let size_index = size_widths.findIndex((width) => +target_width <= +width);
		const size_code =
			size_codes[size_index !== -1 ? size_index : size_codes.length - 1];

		// if different mode that previously
		if (target.current_viewport_mode !== size_code) {
			target.current_viewport_mode = size_code;

			target.classList.remove(...size_codes.map((code) => mode_prefix + code));
			target.classList.add(mode_prefix + size_code);

			target._size = size_code;
			target.ondevicechange && target.ondevicechange();
			target.onviewportchange && target.onviewportchange(target._size);
		}

		// check orientation
		const orientation = target_width > target_height ? "landscape" : "portrait";
		if (target.current_viewport_orientation !== orientation) {
			target.classList.remove("landscape", "portrait");
			target.classList.add(orientation);
			target.current_viewport_orientation = orientation;

			target.onorientationchange && target.onorientationchange();
		}

		setTimeout(
			() =>
				(target === window ? document.body : target).classList.remove(
					"resizing"
				),
			500
		);

		target !== window && target.onResize && target.onResize(size_code);
		return size_code;
	}
}

export function process_field_errors(errors, field, label) {
	if (!errors || !errors[field]) {
		return "";
	}
	return errors[field].replace(new RegExp(`${field}`, "ig"), label);
}

/**
 * Given an array of objects, returns object that has value[key_name] = value
 * @param {array} array
 * @param {string, int} value
 * @param {string} key_name
 * @returns
 */
export function getKeyByValueInArray(array, value, key_name) {
	return array.find((option) => option[key_name] === value);
}

/**
 * Given an array of objects, returns index of object that has value[key_name] = value
 * @param {array} array
 * @param {string, int} value
 * @param {string} key_name
 * @returns
 */
/*export function getIndexByValueInArray(array, value, key_name) {
	return array.findIndex((option) => option[key_name] === value);
}*/

// Function to set correct z-index on bootstrap modals and backdrop overlay
// when several modal over modal opened at a time.
/*export function bsModalOverModal(modalClass) {
	let zIndex = 1040 + 10 * document.querySelectorAll(".modal").length;
	let modals = document.getElementsByClassName(modalClass);
	if (modals.length) {
		for (let eleIdx = 0;eleIdx < modals.length;eleIdx++) {
			modals[eleIdx].style.zIndex = zIndex;
		}
		setTimeout(() => {
			let backdrops = [...document.querySelectorAll(".modal-backdrop:not(.modal-stack)")];
			backdrops.forEach(backdrop => {
				if (!backdrop.nextSibling.classList.contains('.modal')) {
					backdrop.style.display = 'none';
				}
				backdrop.style.zIndex = zIndex - 5;
				backdrop.classList.add("modal-stack");
			})
		}, 0);
	}
}*/

export const randomColor = () => {
	return "#" + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0').toUpperCase();
}

/**
 * 
 * @param {function} fn function that will be called if set_long_throttle has been called more than timeout_ms later
 * @param {string} key name of variable to keep track of the time set_long_throttle was called
 * @param {number} timeout_ms time in ms before fn can be called again.
 * @returns 
 * 
 * Example usecase: used in throttling logging of pages. So the logger function won't execute unless it's been more than 5 mins
 */
export function set_long_throttle(fn, key, timeout_ms) {
	const now = Date.now();
	const storage_key = "throttle";
	const throttle_times = JSON.parse(localStorage.getItem(storage_key)) || {};
	const last_hit = throttle_times[key] || 0;
	const do_log = timeout_ms < (now - last_hit);

	//if timeout_ms time hasn't elapsed, then don't trigger passed fn
	if (!do_log) {
		clear_expired_localStorage(throttle_times, now, timeout_ms);
		return false;
	}

	//if timeout_ms time HAS elapsed, then updated variable with current time
	//and trigger passed fn
	throttle_times[key] = now;
	clear_expired_localStorage(throttle_times, now, timeout_ms);
	return fn();
}


function clear_expired_localStorage(obj, now, timeout_ms) {
	const storage_key = "throttle";
	const filtered_expired = Object.entries(obj).filter(([key, value]) => timeout_ms > (now - value));
	const converted_filtered_expired = Object.fromEntries(filtered_expired);

	localStorage.setItem(storage_key, JSON.stringify(converted_filtered_expired));
}


/**
 * 
 * @param {context} apiContext 
 * @param {string} url 
 * @param {object} page 
 * @param {string} action 
 * @param {number} asset 
 * @param {number} item 
 * @param {*} AxiosAPIClient 
 * @returns 
 */
export function post_log_request(apiContext, url, page, action, asset, item, AxiosAPIClient) {
	if (+apiContext.apiKey === 1) {
		return;
	}
	const url_object = new URL(url);
	const slug = url_object.pathname + url_object.search;
	const payload = {
		page: page.id,
		action: action,
		asset: asset,
		item: item,
		url,
		slug,
		type: page.type?.code
	};

	AxiosAPIClient.post('/Catalog_Activity', payload, {
		headers: {
			"api-key": apiContext.apiKey,
			account: apiContext.account
		}
	}).catch(error => console.warn("Missing permission to save activity.", error));
}