import axios from "axios";
import { X_API_KEY } from "../constants";
import IdentityClient from "./IdentityClient";
import { Cache } from "../util/Cache.util";

export class LiveApiCache {
	static #AUTH_EXPIRATION_SECS = 60;
	static #Token;
	static #Expires_On;

	static Set(token) {
		this.#Token = token;
		this.#Expires_On = this.#Get_Expiration();
		Cache.Session().set("api-token", { token, expires_on: this.#Expires_On })
	}

	static Get() {
		if (this.#Token && this.#Expires_On > Date.now()) {
			return this.#Token;
		}
		else {
			const stored = Cache.Session().get("api-token");
			if (stored?.expires_on > Date.now()) {
				return stored.token;
			}
		}
	}

	static Reset() {
		this.#Token = this.#Expires_On = undefined;
		Cache.Session().set("api-token", undefined);
	}

	static #Get_Expiration() {
		return Date.now() + (this.#AUTH_EXPIRATION_SECS * 1000);
	}
}

const LiveApiClient = axios.create({
	baseURL: process.env.REACT_APP_API_CLIENT_BASE_URL || "https://staging.bosslogics.live/api",
	headers: {
		Authorization: "",
		Project: "together.boss",
		Accept: "application/json",
		'Cache-Control': 'no-cache',
		'Pragma': 'no-cache',
		'Expires': '0',
	},
});

LiveApiClient.interceptors.request.use(
	async config => {
		const savedToken = LiveApiCache.Get();
		if (savedToken) {
			config.headers.Authorization = savedToken || '';
			return config;
		}

		if (X_API_KEY) {
			config.headers['x-api-key'] = X_API_KEY;
			return config;
		}
		LiveApiCache.Reset();

		const { data: { token } } = await IdentityClient.post("getToken");
		config.headers.Authorization = token;
		LiveApiCache.Set(token);

		Object.keys(config.headers).forEach(key => {
			if (config.headers[key] === undefined) {
				delete config.headers[key];
			}
		});

		return config;
	},
	error => Promise.reject(error)
);

LiveApiClient.interceptors.response.use(
	response => response,
	error => {
		if (error.response?.status !== 401) {
			return Promise.reject(error);
		}
		localStorage.removeItem('catalog_invitation:data');
		localStorage.removeItem("organization_member:data");
		localStorage.removeItem("currentUser");
		window.location = '/login?redirect=' + window.location.pathname;
		return Promise.reject(error);
	}
);

export default LiveApiClient;
