import ApiDrivenModel from "../ApiDrivenModel";
import Item from "../Item";

export default class CustomerListItem extends ApiDrivenModel {
	static ENDPOINT = "Customer_List_Item";
	static PATHS = {
		id: true,
		item: Item.PATHS,
	};

	id;
	/** @type Number */
	customer_list;
	/** @type Item */
	item;

	/**
	 *
	 * @param {Item|Number} item
	 */
	setItem (item) {
		if (item instanceof Item) {
			this.item = item;
		}
		else if (Number.isSafeInteger(item) && item > 0) {
			this.item = new Item({ id: item });
		}
		return this;
	}

	constructor(properties) {
		super(properties);

		// Setup children
		if (this.item) {
			this.item = new Item(this.item);
		}
	}
}
