import React from "react";
import { Cache } from "../util/Cache.util";
import { CanceledError } from "axios";
import CustomError from "../models/CustomError";
import CustomerList from "../models/customer/CustomerList";
import CustomerListType from "../models/customer/CustomerListType";
import CustomerListItem from "../models/customer/CustomerListItem";
import Customer from "../models/customer/Customer";
import Customer_Contact from "../models/customer/Customer_Contact";

/**
 * @typedef {Item|Number} ItemLike
 * @typedef {CustomerListItem|Item|Number} CustomerListItemLike
 * @typedef {CustomerList|Number} CustomerListLike
 */

const ALLOWED_ACCOUNT_TYPE_CODES = ["live-plus"];
/** @type CustomerList[] */
const LISTS_CACHE = [];

const CustomerProviderContext = React.createContext({
	customer: undefined,
	getStoredCustomer: () => {},
	/** @returns Promise */ search: (criteria) => {},
	/** @returns Promise */ setCustomer: (customer) => {},
	/** @returns Promise */ fromHandle: ({handle}) => {},
	/** @returns Promise<Boolean> */ exists: ({ email, phone_mobile }) => {},
	/** @returns Promise */ getBookmarksList: () => {},
	/** @returns Promise */ clearBookmarks: () => {},
	/** @returns Promise */ addBookmark: itemId => {},
	/** @returns Promise */ removeBookmark: itemId => {},
	/** @returns Promise */ removeBookmarks: itemIds => {},
	/** @returns Promise<Array> */ getLists: (types = undefined) => {},
	/** @returns Promise */ addCustomer: ({ first_name, last_name, email, phone_mobile }) => {},
	/** @returns Promise */ editCustomer: ({ first_name, last_name, email, phone_mobile }) => {},
	/** @returns Boolean */ isAllowed: catalog => {},

	/**
	 * @param {string} name Name of the list; defaults to Type's Label if name nullish and type passed
	 * @param {string} type Customer_List_Type key
	 * @param {CustomerListItemLike[]} items
	 * @returns Promise
	 */
	createList: (name, type = undefined, items = undefined) => {},

	/** @returns Promise */ assignCustomer: (customer, bookmarkContext) => {},
	/** @returns Promise */ unassignCustomer: (bookmarkContext) => {},
	/** @param {CustomerListLike} list @returns Promise */ addToList: (list, item) => {},
	/** @param {CustomerListLike} list @returns Promise */ removeFromList: (list, item) => {},
	/** @param {CustomerListLike} list @returns Promise */ clearList: list => {},
	/** @param {CustomerListLike} list @returns Promise */ removeList: list => {},
});


export const useCustomer = () => {
	const context = React.useContext(CustomerProviderContext);
	if (!context) {
		throw new Error("useAuth must be used within a CustomerProviderContext");
	}
	return context;
};

export const CustomerProvider = ({ children }) => {
	const getStoredCustomer = () => Cache.Session().get("customer");

	const [customer, _setCustomer] = React.useState(getStoredCustomer());

	const setCustomer = customer => {
		store(customer);
		_setCustomer(customer);
	}

	const getCurrentCustomer = () => customer ?? getStoredCustomer();


	const store = c => {
		Cache.Session().set("customer", c ? {
			handle: c.handle,
			first_name: c.first_name,
			last_name: c.last_name,
			profile_image: c.profile_image,
		} : undefined);
	}

	const search = async (criteria) => {
		const filter = criteria.trim().split(" ").map(n => n.trim()).filter(n => n.length).map(term =>
			[
				"{primary_contact.family_name}",
				"{primary_contact.given_name}",
				"{primary_contact.phone}",
				"{primary_contact.phone_mobile}",
				"{primary_contact.email}",
			].map(col => ({
				property: col,
				operator: "LIKE",
				value: `*${term.toString()}*`
			}))
		);
		// ensure customer info isn't missing
		filter.unshift(["first_name", "last_name", "email", "phone_mobile"].map(property => ({ property, operator: "!=", value: "" })));
		filter.unshift(["first_name", "last_name", "email", "phone_mobile"].map(property => ({ property, operator: "!=", value: null })));

		const found_customers = await Customer.SearchBy({
			filter,
			limit: 100,
			order: JSON.stringify(["last_name", "first_name"]),
		});
		return found_customers;
	}

	const isAllowed = catalog => {
		return catalog && ALLOWED_ACCOUNT_TYPE_CODES.includes(catalog.account?.type?.code);
	}

	const fromHandle = async ({ handle }) => {
		let paths = {
			id: true,
			handle: true,
			first_name: true,
			last_name: true,
			phone_mobile: true,
			email: true,
			profile_image: true,

			type: ["code"],
			notes: {
				"*": true,
				profile: { first_name: true, last_name: true, profile_image: true, },
			},
			interactions: {
				"*": true,
				associate: ["first_name", "last_name", "email", "profile_image", "title"]
			},
			locations: ["*"],
			contacts: ["*"],
			customer_items: ["*"],
			customer_location_items: {
				item: ["*"],
			},
			proposals: ["*"],
			sales_orders: ["*"],
			delivery_location: ["*"],
			relationships: ["*"],
		};

		try {
			const found_customer = await Customer.FetchBy({
				paths,
				filter: { handle },
			})
			return found_customer;
		}
		catch (e) {
			if (!(e instanceof CanceledError)) {
				throw e;
			}
		}
	}

	const getListTypes = async () => {
		return await CustomerListType.SearchBy();
	}

	/**
	 * @param {string} name Name of the list; defaults to Type's Label if name nullish and type passed
	 * @param {string} type Customer_List_Type key
	 * @param {CustomerListItemLike[]} items
	 */
	const createList = async (name, type = undefined, items = undefined) => {
		const list = new CustomerList();
		list.customer = customer?.id;
		list.label = name;
		list.addItems(items);

		if (type) {
			const listType = await CustomerListType.FetchBy({ filter: { key: type } });
			if (listType) {
				list.type = listType.id;
				list.label ??= listType.label
			}
		}

		await list.save();
		return list;
	}

	const getLists = async (types = undefined) => {
		const lists = await CustomerList.SearchBy({
			filter: [
				{ property: "{customer.handle}", operator: "=", value: getCurrentCustomer().handle },
				types ? { property: "{type.key}", operator: "=", value: types } : undefined,
			].filter(f => f)
		});

		LISTS_CACHE.splice(0);
		LISTS_CACHE.push(...lists);
		return lists;
	}

	/**
	 * @param {Number} listId
	 * @param {ItemLike} item
	 */
	const addToList = async (listId, item) => {
		const listItem = new CustomerListItem();
		listItem.customer_list = listId;
		listItem.setItem(item);
		await listItem.save();
		return listItem;
	}

	const removeFromList = async (listId, itemId) => {
		const list = await CustomerList.FindById(listId);
		const item = list?.items?.find(item => item.item?.id === itemId);
		await item?.remove();
	}

	/**
	 * @param {CustomerListLike} list
	 */
	const clearList = async list => {
		const listToClear = list instanceof CustomerList ? list : await CustomerList.FindById(list);
		await Promise.allSettled(listToClear?.items?.map(item => item.remove()));
	}

	/**
	 * @param {CustomerListLike} list
	 */
	const removeList = async list => {
		const listToRemove = list instanceof CustomerList ? list : await CustomerList.FindById(list);
		if (listToRemove) {
			await listToRemove.remove();
		}
	}

	const exists = async ({ email, phone_mobile }) => {
		if (!email && !phone_mobile) return false;

		const filter = [ ["email", email], ["phone_mobile", phone_mobile] ].reduce((acc, [property, value]) => {
			if (value) acc.push({ property, operator: "=", value });
			return acc;
		}, []);

		const currentCustomer = await Customer.FetchBy({
			filter: [ filter ],
		});
		return currentCustomer?.handle;
	}


	/**
	 * @param {{ first_name, last_name, email, phone_mobile }} props
	 */
	const editCustomer = async (props) => {
		if (!customer) return;
		["first_name", "last_name", "email", "phone_mobile"].forEach(prop => {
			customer[prop] = props[prop] ?? "";  // without blank you won't be able to remove during edit
		});
		await customer.save();
	}

	/**
	 *
	 * @param {{ first_name, last_name, email, phone_mobile }} props
	 */
	const addCustomer = async (props) => {
		const { first_name, last_name, email, phone_mobile } = props;
		const existingCustomerHandle = await exists({
			email,
			phone_mobile,
		});
		if (existingCustomerHandle) {
			throw new CustomError("CustomerExists", {
				message: "Customer already exists",
				handle: existingCustomerHandle
			});
		}

		const primary_contact = new Customer_Contact({
			given_name: first_name,
			family_name: last_name,
			email,
			phone_mobile,
		});

		const customer = new Customer({
			primary_contact,
			first_name,
			last_name,
			email,
			phone_mobile,
		});
		await customer.save();

		if (!customer?.id) {
			throw new Error("Failed to create customer");
		}

		return { customer, primary_contact }
	}

	const assignCustomer = async (customer, bookmarkContext) => {
		setCustomer(customer);
		const customerBookmarksList = await getBookmarksList();
		const customerBookmarks = customerBookmarksList?.items;
		const customerBookmarkItemIds = customerBookmarks?.filter(it => it.item)?.map(it => it.item.id) ?? [];
		const associateBookmarkItemIds = bookmarkContext.bookmarkItems?.filter(it => it.item)?.map(it => it.item.id) ?? [];
		const combinedBookmarkItemIds = new Set([...customerBookmarkItemIds, ...associateBookmarkItemIds]);
		const bookmarkSaves = [];

		combinedBookmarkItemIds.forEach(itemId => {
			if (!associateBookmarkItemIds.includes(itemId)) {
				bookmarkSaves.push(bookmarkContext.addBookmarkItem(itemId));
			}
			else if (!customerBookmarkItemIds.includes(itemId)) {
				bookmarkSaves.push(addBookmark(itemId));
			}
		});

		await Promise.allSettled(bookmarkSaves);
	}

	const unassignCustomer = (bookmarkContext) => {
		return Promise.allSettled([
			setCustomer(undefined),
			bookmarkContext.clearBookmarkItems(),
			removeBookmarksListCache(),
		]);
	}

	const removeListCache = id => {
		const listIndex = LISTS_CACHE.findIndex(l => l.id === id);
		if (listIndex !== -1) {
			LISTS_CACHE.splice(listIndex, 1);
		}
	}

	// ------------ helpers for Bookmarks list --------------//

	const removeBookmarksListCache = async () => {
		const bookmarksList = await getBookmarksList();
		if (bookmarksList) {
			removeListCache(bookmarksList.id);
		}
	}

	const getBookmarksList = async () => {
		const cached = LISTS_CACHE.find(l => l.type?.key === "bookmarks");
		if (cached) {
			return cached;
		}
		const lists = await getLists(["bookmarks"]);
		return lists?.at(0);
	}

	const getOrMakeBookmarksList = async () => {
		const bookmarksList = (await getBookmarksList())
			?? (await createList(undefined, "bookmarks"));
		return bookmarksList;
	}

	const addBookmark = async itemId => {
		const bookmarksList = await getOrMakeBookmarksList();
		return addToList(bookmarksList.id, itemId);
	}

	const removeBookmark = async itemId => {
		await removeBookmarksListCache();
		const bookmarksList = await getBookmarksList();
		const bookmarkItem = bookmarksList?.items?.find(it => it.item?.id === itemId);
		await bookmarkItem?.remove();
	}

	const removeBookmarks = async itemIds => {
		await removeBookmarksListCache();
		const bookmarksList = await getBookmarksList();

		return bookmarksList.items
			.filter(it => itemIds.includes(it.item.id))
			.map(it => it.remove());
	}

	const clearBookmarks = async () => {
		const bookmarksList = await getOrMakeBookmarksList();
		return clearList(bookmarksList);
	}

	// ------------------------------------- //


	const value = {
		customer,

		getStoredCustomer,
		search,
		setCustomer,
		fromHandle,
		exists,
		isAllowed,

		addCustomer,
		editCustomer,
		assignCustomer,
		unassignCustomer,

		getLists,
		createList,
		addToList,
		removeFromList,
		clearList,
		removeList,

		getBookmarksList,
		clearBookmarks,
		addBookmark,
		removeBookmark,
		removeBookmarks,
	};

	return <CustomerProviderContext.Provider value={value}>{children}</CustomerProviderContext.Provider>;
};
