import axios from "axios";
import { IDENTITY_AUTH_PROXY } from "../constants";

let tokenRequest;

const BASE_URL = window.location.protocol + "//" + window.location.host + "/identity";

const IdentityClient = axios.create({
	baseURL: IDENTITY_AUTH_PROXY || BASE_URL,
	headers: {
		"Content-Type": "application/x-www-form-urlencoded",
		Accept: "application/json",
	},
});

IdentityClient.interceptors.request.use(async config => {
	if (config.baseURL !== "/getToken" && config.baseURL !== "getToken" && new URL(config.baseURL, window.location.origin).pathname !== "getToken") {
		tokenRequest = await fetch(`${BASE_URL}/getToken`, {
			method: "POST",
		}).then(r => r.json())
	}
	const { token } = tokenRequest;
	if (config.data) {
		config.data.auth = token;
	}

	return config;
});

// IdentityClient.interceptors.response.use(
// 	response => {
// 		// TODO return response.data ... but usages need updating
// 		return response;
// 	},
// 	error => () => error,
// );

export default IdentityClient;