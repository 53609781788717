import ApiDrivenModel from "./ApiDrivenModel";
import { Constant } from "../util/Constant";

export default class Item extends ApiDrivenModel {
	constructor(properties) {
		super(properties);
		delete this.data; // prevent circular serialization error with JSONing later
	}

	getMeta() {
		return {
			handle: this.common_sku,
			title: this.name,
			description: this.description,
			image: this.media?.find((m) => m.type === "image")?.source
		}
	}

	/**
	 * @returns {URL}
	 */
	static Make_Share_PDF_Url (account, items) {
		const url = new URL(`/generate/${account}/Items.pdf`, Constant.Get("cdn"));
		url.searchParams.set("items", items.map(it => it.handle));
		return url;
	}

	static ENDPOINT = "Item";

	static PATHS = {
		id: true,
		common_sku: true,
		title: true,
		name: true,
		description: true,
		image_0: true,
		image_1: true,
		image_2: true,
		image_3: true,
		image_4: true,
		video_0: true,
		video_1: true,
		video_2: true,
		video_3: true,
		video_4: true,
		sell_price: true,
		pdf: true,
		gemstone: true,
		handle: true,
	};
}