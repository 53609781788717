import ApiDrivenModel from "../ApiDrivenModel";

export default class Customer_Contact extends ApiDrivenModel {
	static ENDPOINT = "/Customer_Contact";
	static PATHS = {
		id: true,
		given_name: true,
		family_name: true,
		email: true,
		phone_mobile: true,
		customer: ["*"],
	};

	id;
	given_name;
	family_name;
	phone_mobile;
	email;
}
