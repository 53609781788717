import React from "react";

const CatalogSelectionContext = React.createContext({
	clearSearchAfter: undefined,
	clearSearch: () => {},
});

export const useCatalogSelectionContext = () => {
	const context = React.useContext(CatalogSelectionContext);
	if (!context) {
		throw new Error(
			"useCatalogSelectionContext must be used within a CatalogSelectionContextProvider"
		);
	}
	return context;
};

export const CatalogSelectionContextProvider = ({ children }) => {
	const [clearSearchAfter, setClearSearchAfter] = React.useState(undefined)

	const clearSearch = () => {
		setClearSearchAfter(Date.now());
	}

	const value = {
		clearSearch,
		clearSearchAfter,
	};

	return (
		<CatalogSelectionContext.Provider value={value} displayName="Catalog Selection Context">
			{children}
		</CatalogSelectionContext.Provider>
	);
};
